.data-table-action-field {
  text-align: center;
}

.data-table-action-field button {
  margin: 0 .2em;
}

.groupSelector {
  float: right;
  margin-bottom: 1em;
}